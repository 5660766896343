import glRequest from '@/utils/request/instance'
import { IAccount, IDataType, ILoginResult } from './type'

enum LoginAPI {
  AccountLogin = '/login',
  LoginUserInfo = '/login/getLoginInfo'
}

export function accountLoginRequest(account: IAccount) {
  return glRequest.post<IDataType>({
    url: LoginAPI.AccountLogin,
    data: account
  })
}

export function getLoginUserInfo() {
  return glRequest.get<IDataType>({
    url: LoginAPI.LoginUserInfo
  })
}
