import { Module } from 'vuex'

import { ILoginState } from './types'
import { IRootState } from '../types'
import { accountLoginRequest, getLoginUserInfo } from '@/api/login/login'
import LocalCatche from '@/utils/localStorage'
import { IAccount } from '@/api/login/type'
import { mapMenusToRoutes } from '@/utils/mapMenus'
import router from '@/router'

const loginModule: Module<ILoginState, IRootState> = {
  namespaced: true,
  state: {
    token: '',
    userInfo: {},
    userMenus: []
  },
  mutations: {
    changeToken(state, token: string) {
      state.token = token
    },
    changeUserInfo(state, userInfo: any) {
      state.userInfo = userInfo
    },
    changeUserMenus(state, userMenus: any) {
      state.userMenus = userMenus

      const routes = mapMenusToRoutes(userMenus)
      routes.forEach((route) => {
        router.addRoute('main', route)
      })
    }
  },
  getters: {},
  actions: {
    async accountLoginAction({ commit, state }, payload: IAccount) {
      const loginResult = await accountLoginRequest(payload)
      // console.log('111', loginResult.data.id)
      loginResult.data = {
        token:
          'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MSwibmFtZSI6IueuoeeQhuWRmCIsInR5cGUiOjEsImRldmljZSI6MSwic3VwZXJQYXNzd29yZEZsYWciOmZhbHNlLCJpYXQiOjE2Nzk0ODkxOTEsImV4cCI6MTY4MDA5Mzk5MX0.UucZqogye5ytwErYeD76inIJCC3gTEyyxR3Nkyoy07vODKz3GhjfppMAHdyXvtcsTCKW_9mFZMxKzB0SGBWVXw',
        employeeId: 1,
        userType: 'ADMIN_EMPLOYEE',
        loginName: 'admin',
        actualName: '管理员',
        gender: 0,
        phone: '13500000000',
        departmentId: 1,
        departmentName: '1024创新实验室',
        administratorFlag: true,
        lastLoginIp: '183.253.26.175',
        lastLoginUserAgent:
          'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/111.0.0.0 Safari/537.36',
        lastLoginTime: '2023-03-18 16:13:04',
        ip: null,
        userAgent: null,

        enabled: true,
        userId: 1,
        userName: '管理员',
        username: 'admin',
        accountNonExpired: true,
        accountNonLocked: true,
        credentialsNonExpired: true
      }
      const { userId: id, token } = loginResult.data
      commit('changeToken', token)
      LocalCatche.setCatch('token', token)

      const userInfoResult = await getLoginUserInfo()
      console.log(state.token)
      commit('changeUserInfo', userInfoResult.data)
      LocalCatche.setCatch('userInfo', userInfoResult.data)
    }
    // phoneLoginAction({ commit }, payload: any) {
    //   console.log('执行手机登录')
    // }
  }
}

export default loginModule
