import GLRequest from './index'
import localStorage from '../localStorage'
const glRequest = new GLRequest({
  // baseURL: '地址1'
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 10000,
  interceptors: {
    requestInterceptor: (config) => {
      const token = localStorage.getCatch('token')
      if (token) {
        // 携带token拦截器
        config.headers['x-access-token'] = token
      }
      return config
    }
  }
})

export default glRequest
