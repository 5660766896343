export function param2Obj(url: string) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  if (decodeURIComponent(search)) {
    return JSON.parse(
      `{"${decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ')}"}`
    )
  }
}
/**
 * 获取时间间隔
 * @param startTime
 * @param endTime
 */
export function getTimeInterval(startDate: number | Date, endDate: number | Date): string {
  let startTime
  let endTime
  if (typeof startDate === 'object') {
    startTime = startDate.getTime()
  } else {
    startTime = startDate
  }
  if (typeof endDate === 'object') {
    endTime = endDate.getTime()
  } else {
    endTime = endDate
  }
  const dateInterval = endTime - startTime
  // //计算出相差天数
  const days = Math.floor(dateInterval / (24 * 60 * 60 * 1000))
  //计算小时数
  const hourLevel = dateInterval % (24 * 60 * 60 * 1000)
  const hours = Math.floor(hourLevel / (60 * 60 * 1000))
  //计算分钟数
  const minutesLevel = hourLevel % (60 * 60 * 1000)
  const minutes = Math.floor(minutesLevel / (60 * 1000))
  //计算秒数
  const seconds = Math.round((minutesLevel % (60 * 1000)) / 1000)
  return `${days} 天 ${hours} 时 ${minutes} 分 ${seconds} 秒`
}
