import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus, { dayjs } from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router'
import store from './store'
// import glRequest from './utils/request/instance'
import './assets/font/iconfont.css'
import { globalRegister } from './global'
import hljs from 'highlight.js'
import 'highlight.js/styles/atom-one-dark.css' //样式
// require('./Mock')

import '@/utils/request'
import 'normalize.css'
import '@/assets/css/index.less'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

const app = createApp(App)

app.directive('highlight', function (el) {
  const blocks = el.querySelectorAll('pre code')
  blocks.forEach((block: any) => {
    hljs.highlightBlock(block)
  })
})

app.use(globalRegister)
app.use(mavonEditor)

app.use(store)
app.use(ElementPlus)
app.use(router)

app.mount('#app')
