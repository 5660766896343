import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from 'vue-router'
// import HomeView from '@/views/main/HomeView.vue'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/main',
    meta: { title: '首页' }
  },
  {
    path: '/category/:cate/:categoryId',
    name: 'category',
    component: () => import('@/views/main/MainIndex.vue'),
    meta: { title: '分类', params: 'cate' }
  },

  {
    path: '/search/:words',
    name: 'search',
    component: () => import('@/views/main/MainIndex.vue'),
    meta: { title: '搜索', params: 'words' }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/main/AboutIndex.vue'),
    meta: { title: '关于' }
  },
  {
    path: '/friend',
    name: 'friend',
    component: () => import('@/views/main/FriendIndex.vue'),
    meta: { title: '友链' }
  },
  {
    path: '/article/:id',
    name: 'article',
    component: () => import('@/views/main/ArticlesIndex.vue'),
    meta: { title: '文章' }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue')
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('@/views/main/MainIndex.vue'),
    meta: { title: '首页' }
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/not-found/not-found.vue')
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from) => {
  let title = 'Gu-Blog'
  // console.log(to)
  // if (to.meta.params) {
  //   title = `${to.meta.title}:${to.params[to.meta.params] || ''} - ${title}`
  // } else {
  title = `${to.meta.title} - ${title}`
  // }
  document.title = title
  if (to.path !== from.path) {
    store.dispatch('setLoading', true)
  }
  // if (to.path !== '/login') {
  //   const token = localStorage.getItem('token')
  //   if (!token) {
  //     return '/login'
  //   }
  // }
})
router.afterEach((to, from) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  // 最多延迟 关闭 loading
  setTimeout(() => {
    store.dispatch('setLoading', false)
  }, 1000)
})

export default router
