import glRequest from '@/utils/request/instance'
import { ICategory, IDataType } from './type'

enum MainAPI {
  getCategory = '/categorys/detail',
  getSocial = '/social',
  getSite = '/site',
  // getList = '/post/list',
  getList = '/articles',
  // getFocusList = '/tags/hot',
  getFocusList = '/articles/hot',
  getComment = '/articles/view',
  getFriend = '/friend'
}

export function fetchCategory() {
  return glRequest.get<IDataType<ICategory[]>>({
    url: MainAPI.getCategory
  })
}

export function fetchSocial() {
  return glRequest.get<IDataType<ICategory[]>>({
    url: MainAPI.getSocial
  })
}

export function fetchSiteInfo() {
  return glRequest.get<IDataType<any>>({
    url: MainAPI.getSite
  })
}
export function fetchList(params?: any) {
  return glRequest.post<IDataType<any>>({
    url: MainAPI.getList,
    data: params
  })
}
export function fetchFocus(params?: any) {
  return glRequest.post<IDataType<any>>({
    url: MainAPI.getFocusList,
    data: params
  })
}

export function fetchComment(id: any) {
  return glRequest.post<IDataType<any>>({
    url: MainAPI.getComment + `/${id}`
  })
}

export function fetchFriend() {
  return glRequest.get<IDataType<any>>({
    url: MainAPI.getFriend
  })
}
