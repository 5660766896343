import { createStore, Store, useStore as useVuexStore } from 'vuex'
import { IRootState, IStoreType } from './types'
import login from './login/login'
import { getTimeInterval } from '@/utils'
import { fetchSocial, fetchSiteInfo } from '@/api/main/main'
import dayjs from 'dayjs'

// console.log(dayjs().valueOf())
const runAt = 1684414229503
let timer: ReturnType<typeof setTimeout>
export default createStore<IRootState>({
  state: {
    name: 'gulang',
    age: 11,
    loading: false,
    runTimeInterval: '',
    websiteInfo: '',
    socials: '',
    socialsList: [
      {
        id: '2',
        title: 'Gitee',
        icon: 'icongitee',
        color: '#d81e06',
        href: 'https://gitee.com/gu_lang107'
      },
      {
        id: '3',
        title: 'GitHub',
        icon: 'icongithub',
        color: '',
        href: 'https://github.com/Gulang10783'
      },
      {
        id: '4',
        title: 'CSDN',
        icon: 'iconcsdn',
        color: 'red',
        href: 'https://blog.csdn.net/Gulang_107'
      }
    ]
  },
  getters: {
    loading: (state) => state.loading,
    runTimeInterval: (state) => state.runTimeInterval,
    notice: (state) => (state.websiteInfo ? state.websiteInfo.notice : '')
  },
  mutations: {
    SET_LOADING: (state, v) => {
      state.loading = v
    },
    changeName(state) {
      state.name = '222'
    },

    SET_SOCIALS: (state, v) => {
      state.socials = v
    },
    SET_SITE_INFO: (state, v) => {
      state.websiteInfo = v
    },
    GET_RUNTIME_INTERVAL: (state) => {
      if (!timer || !state.runTimeInterval) {
        clearInterval(timer)
        timer = setInterval(() => {
          state.runTimeInterval = getTimeInterval(runAt, dayjs().valueOf())
        }, 1000)
      }
    }
  },
  actions: {
    setLoading: ({ commit }, v) => {
      commit('SET_LOADING', v)
    },
    getSiteInfo: ({ commit, state }) => {
      return new Promise((resolve) => {
        if (state.websiteInfo) {
          resolve(state.websiteInfo)
        } else {
          fetchSiteInfo()
            .then((res) => {
              const data = res.data || {}
              commit('SET_SITE_INFO', data)
              resolve(data)
            })
            .catch((err) => {
              resolve({})
            })
        }
      })
    },

    getSocials: ({ commit, state }) => {
      return new Promise((resolve) => {
        if (state.socials) {
          resolve(state.socials)
        } else {
          fetchSocial()
            .then((res) => {
              const data = res.data || []
              commit('SET_SOCIALS', data)
              resolve(data)
            })
            .catch((err) => {
              resolve([])
            })
        }
      })
    },
    initComputeTime: ({ commit }) => {
      commit('GET_RUNTIME_INTERVAL')
    }
  },
  modules: {
    login
  }
})

export function setupStore() {
  // vuex数据持久化
}
export function useStore(): Store<IStoreType> {
  return useVuexStore()
}
