import axios from 'axios'
import type { AxiosInstance } from 'axios'

import type { GLRequestConfig, GLRequestInterceptors } from './type'
import { ElLoading } from 'element-plus'
import { LoadingInstance } from 'element-plus/lib/components/loading/src/loading'

const DEFAULT_LOADING = false

class GLRequest {
  instance: AxiosInstance
  interceptors?: GLRequestInterceptors
  loading?: LoadingInstance
  showLoading: boolean

  constructor(config: GLRequestConfig) {
    this.instance = axios.create(config)
    this.interceptors = config.interceptors
    this.showLoading = config.showLoading ?? DEFAULT_LOADING

    // 添加所有实例的拦截器
    this.instance.interceptors.request.use(
      (config) => {
        if (this.showLoading) {
          this.loading = ElLoading.service({
            lock: true,
            text: '正在请求数据...',
            background: 'rgba(0,0,0,0.5)'
          })
        }
        return config
      },
      (err) => {
        return err
      }
    )
    this.instance.interceptors.response.use(
      (res) => {
        // setTimeout(() => {
        this.loading?.close()
        // }, 1999)
        return res.data
      },
      (err) => {
        this.loading?.close()
        return err
      }
    )

    // 从config取出的拦截器对应的是实例的拦截器
    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestInterceptorCatch
    )
    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor,
      this.interceptors?.responseInterceptorCatch
    )
  }
  request<T>(config: GLRequestConfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      if (config.interceptors?.requestInterceptor) {
        config = config.interceptors.requestInterceptor(config)
      }

      if (config.showLoading) {
        this.showLoading = config.showLoading
      }

      this.instance.interceptors.request.use((config) => {
        return config
      })
      this.instance
        .request<any, T>(config)
        .then((res) => {
          //单个请求对数据的处理
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors.responseInterceptor(res)
          }
          // 将showloading重置，不会影响下一个请求
          this.showLoading = DEFAULT_LOADING
          // 把返回结果return出去
          resolve(res)
        })
        .catch((err) => {
          this.showLoading = DEFAULT_LOADING
          // return err
          reject(err)
        })
    })
  }

  get<T>(config: GLRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'GET' })
  }

  post<T>(config: GLRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'POST' })
  }

  delete<T>(config: GLRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'DELETE' })
  }

  put<T>(config: GLRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'PUT' })
  }
}
function getcookie(objname: string) {
  //获取指定名称的cookie的值

  const arrstr = document.cookie.split('; ')

  for (let i = 0; i < arrstr.length; i++) {
    const temp = arrstr[i].split('=')

    if (temp[0] == objname) return unescape(temp[1])
  }
}
export default GLRequest
